import CryptoJS from "crypto-js";

const signatures = {
    'JVB': { head: "data:application/pdf;base64", type: ".pdf" },
    '/9j': { head: "data:image/jpeg;base64", type: ".jpeg" },
    'iVB': { head: "data:image/png;base64", type: ".png" },
    // 'R0l': { head: "data:image/gif;base64", type: ".gif" },
    // 'Ukl': { head: "data:image/webp;base64", type: ".webp" },
    // 'TU0': { head: "data:image/tiff;base64", type: ".tiff" },
    // 'SUk': { head: "data:image/tiff;base64", type: ".tiff" },
};

const signaturesType = [
    { head: "data:application/pdf;base64", type: ".pdf" },
    { head: "data:image/jpeg;base64", type: ".jpeg" },
    { head: "data:image/png;base64", type: ".png" },
    // { head: "data:image/gif;base64", type: ".gif" },
    // { head: "data:image/webp;base64", type: ".webp" },
    // { head: "data:image/tiff;base64", type: ".tiff" },
    // { head: "data:image/tiff;base64", type: ".tiff" },
];

const CapitalizeEachWord = (string = '') => {
    let result = string?.split(' ');

    for (let i = 0; i < result.length; i++) {
        result[i] = result?.[i]?.[0]?.toUpperCase() + result?.[i]?.substring(1);
    }

    return result?.join(' ');
}

const CheckFile = async (file, acceptExtension = null) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async function () {
            const arg = file?.name?.split(".");
            const extension = `.${arg?.pop()?.toLowerCase()}`;
            const contentFile = reader.result.split(",")[1];
            const contentPrefix = reader.result.split(",")[0];
            const allowExtension = TrimArray(
                (acceptExtension ?? process.env.NEXT_PUBLIC_UPLOAD_IMAGE_FORMAT)
                    ?.toLowerCase()
                    ?.split(",")
            );

            let result = {
                corrupted: false,
                encrypted: false,
                invalidExtension: false,
            };

            if (arg?.length > 0 && allowExtension?.includes(extension)) {
                if (
                    GetMIMEType(contentFile) &&
                    (allowExtension?.includes(
                        GetMIMEType(contentFile)?.type?.toLowerCase()
                    ) ||
                        signaturesType.find((item) => item?.type === extension)?.head ===
                        contentPrefix)
                ) {
                    const isEncrypted = await CheckEncryptedFile(file);

                    if (isEncrypted) result.encrypted = true;
                } else {
                    result.corrupted = true;
                }
            } else {
                result.invalidExtension = true;
            }

            resolve(result);
        };
        reader.onerror = (error) => reject(error);
    });

const CheckEncryptedFile = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function () {
            let files = new Blob([reader.result]);
            files.text().then((res) => {
                resolve(
                    ("isEncrypted", res.includes("Encrypt")) ||
                    ("isEncrypted",
                        res
                            .substring(res.lastIndexOf("<<"), res.lastIndexOf(">>"))
                            .includes("/Encrypt"))
                );
            });
        };
        reader.onerror = (error) => reject(error);
    });

const CheckCorruptedFile = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const contentFile = reader.result.split(",")[1];
            const contentPrefix = reader.result.split(",")[0];

            resolve(
                !GetMIMEType(contentFile) ||
                GetMIMEType(contentFile)?.head !== contentPrefix
            );
        };
        reader.onerror = (error) => reject(error);
    });

const CheckEmail = (email = '') => {
    var validRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return email?.match(validRegex);
}

const CheckPassword = (password = '') => {
    const hasUpperCase = /[A-Z]/.test(password)
    const hasNumber = /[\d]/.test(password)
    const hasLowerCase = /[a-z]/.test(password)
    const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(password)
    let validConditions = 0
    const numberOfMustBeValidConditions = 3
    const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbole]

    conditions.forEach(condition => (condition ? validConditions++ : null))

    if (validConditions >= numberOfMustBeValidConditions) {
        return true;
    }

    return false;
}

const EncryptPassword = (password = '') => {
    return CryptoJS.AES.encrypt(password, CryptoJS.enc.Utf8.parse(process.env.NEXT_PUBLIC_PASSWORD_TOKEN), {
        mode: CryptoJS.mode.ECB
    }).toString();
}

const GetInitial = (string = "", length = 1) => {
    const arg = string.split(" ");

    let result = "";

    for (let i = 0; i < length; i++) {
        const item = arg?.[i];

        result += item ? item?.slice(0, 1)?.toUpperCase() : '';
    }

    return result;
};

const GetMIMEType = (base64) => {
    const fileCode = base64?.substring(0, 3);

    return signatures[fileCode];
};

const GrabSlider = (id) => {
    const slider = typeof id === "string" ? document.getElementById(id) : id;
    let isClicked = false;
    let startX = 0;
    let scrollLeft = 0;

    slider.addEventListener("mousedown", (e) => {
        isClicked = true;
        startX = e.pageX + slider.scrollLeft;
        scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
        isClicked = false;
    });
    slider.addEventListener("mouseup", () => {
        isClicked = false;
    });
    slider.addEventListener("mousemove", (e) => {
        if (!isClicked) return;
        e.preventDefault();
        scrollLeft = startX - e.pageX;

        slider.scrollLeft = scrollLeft;
    });

    return () => {
        slider.removeEventListener("mousedown", (e) => {
            isClicked = true;
            startX = e.pageX + slider.scrollLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.removeEventListener("mouseleave", () => {
            isClicked = false;
        });
        slider.removeEventListener("mouseup", () => {
            isClicked = false;
        });
        slider.removeEventListener("mousemove", (e) => {
            if (!isClicked) return;
            e.preventDefault();
            scrollLeft = startX - e.pageX;

            slider.scrollLeft = scrollLeft;
        });
    };
};

const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 80 &&
        rect.left >= 0 &&
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

const KeyDownEnter = (e, func) => {
    if (e.keyCode === 13) func();
    else return;
}

const MaskString = (
    text = "",
    startIndex = null,
    lastIndex = null,
    mask = "*"
) => {
    const result = text
        ?.split("")
        ?.map((item, index, array) =>
            index > startIndex &&
                index < array?.length - lastIndex &&
                ![".", " "]?.includes(item)
                ? mask
                : item
        );

    return result;
};

const QueryURL = (data = null) => {
    const query = new URLSearchParams();

    for (const [key, value] of Object.entries(data)) {
        if (value) query.append(key, value);
    }

    return query?.toString();
};

const ToBase64 = (file) =>
    new Promise((resolve, reject) => {
        if (!file) return;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const TrimArray = (array = []) => {
    return array.map((element) => element.trim());
};

const getUrlPlatformBase = (isMobile) => {
    let url = "user/pay";
    if (isMobile) {
        url = "pay-mobile";
    }
    return url;
};

export {
    CapitalizeEachWord,
    CheckFile,
    CheckEncryptedFile,
    CheckCorruptedFile,
    CheckEmail,
    CheckPassword,
    EncryptPassword,
    GetInitial,
    GetMIMEType,
    GrabSlider,
    isInViewport,
    KeyDownEnter,
    MaskString,
    QueryURL,
    ToBase64,
    TrimArray,
    getUrlPlatformBase,
};
