import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Link from "next/link";
import { usePathname, useSearchParams } from 'next/navigation';
import { useDispatch } from 'react-redux';

import { setNeedSignUp, setNeedSignIn, setReferral } from '@/core/store/reducer/loginSlice';
import Form from "./Form";
import LoadingSpinner from '@/core/components/Loader/LoadingSpinner';

function LoginContent({ fromModal = false, onSuccessLogin }) {
  const [isLoading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const isReset = searchParams.get('ref') === 'reset-password';

  return (
    <Fragment>
      <p className="mb-8 text-xl text-center font-medium">Masuk</p>

      <Form
        setLoading={setLoading}
        onSuccessLogin={onSuccessLogin}
      />

      {!isReset && (
        <>
          <div className="mt-4 text-center">
            <p className="text-sm text-neutral-60">
              Belum punya akun?

              {fromModal ? (
                <button
                  type="button"
                  className="ml-2 text-success-50 font-bold"
                  onClick={() => {
                    dispatch(setNeedSignIn(false));
                    dispatch(setNeedSignUp(true));
                  }}
                >
                  Daftar di sini
                </button>
              ) : (
                <Link
                  href={pathname?.split("?")[1] ? `/register?${pathname?.split("?")[1]}` : "/register"}
                  className="ml-2 text-success-50 font-bold"
                  onClick={() => {
                    dispatch(setNeedSignIn(false));
                    if (pathname?.includes(process.env.NEXT_PUBLIC_IDEAL_OLX_URL)) {
                      dispatch(setReferral(process.env.NEXT_PUBLIC_IDEAL_OLX_REFERRAL))
                    } else if (pathname?.includes(process.env.NEXT_PUBLIC_IDEAL_RUMAH123_URL)) {
                      dispatch(setReferral(process.env.NEXT_PUBLIC_IDEAL_RUMAH123_REFERRAL))
                    } else {
                      dispatch(setReferral(''))
                    }
                  }}>

                  Daftar di sini

                </Link>
              )}
            </p>
          </div>
          <div className="mt-6 text-center">
            <Link
              href="/forgot-password"
              className="text-sm text-secondary-40 font-bold"
              onClick={() => {
                dispatch(setNeedSignIn(false));
              }}>

              Lupa Password?

            </Link>
          </div>
        </>
      )}

      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center py-12 bg-white/80">
          <LoadingSpinner height={24} width={24} />
        </div>
      )}
    </Fragment>
  );
}

LoginContent.propTypes = {
  fromModal: PropTypes.bool,
  onSuccessLogin: PropTypes.func.isRequired,
};

export default LoginContent;
