import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter, useSearchParams } from 'next/navigation';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import Api from '@/configs/Api';
import firebaseMessaging from '@/core/helpers/firebaseMessaging';
import useRequest from '@/core/api/useRequest';
import { firebaseConfig } from '@/configs/Firebase';
import { EncryptPassword } from '@/core/helpers/helper';
import { logAction } from '@/core/helpers/analytics';
import { setAuthData, getFcmToken } from '@/core/store/reducer/authSlice';
import { setNeedSignIn, getRedirectTo, setNeedSignUp } from '@/core/store/reducer/loginSlice';
import { setResponseAction, showError } from '@/core/store/reducer/apiSlice';

import Button from "@/core/components/Button";
import ButtonGoogle from "./ButtonGoogle";
import ButtonApple from "./ButtonApple";
import InputText from "@/core/components/Form/fase-2/InputText";
import Recaptcha from '@/core/components/Recaptcha';

// Initialize Firebase 
export const fbApp = initializeApp(firebaseConfig);

const LoginForm = ({ onSuccessLogin, setLoading }) => {

  const dispatch = useDispatch();

  const [loginError, setLoginError] = useState(null);

  const [getErrorPassword, setErrorPassword] = useState('');
  const [getErrorPhone, setErrorPhone] = useState('');

  const [validTokenV3, setValidTokenV3] = useState(true);
  const [tokenRecaptchaV2, setTokenRecaptchaV2] = useState(null);
  const [showRecaptchaSocial, setShowRecaptchaSocial] = useState(false);

  const [getValuePassword, setValuePassword] = useState('');
  const [getValuePhone, setValuePhone] = useState('');

  let fcmToken = useSelector(getFcmToken);
  const reduxRedirect = useSelector(getRedirectTo);

  const router = useRouter();
  const searchParams = useSearchParams();

  const redirect = searchParams.get('redirect') ? decodeURI(searchParams.get('redirect')) : null;

  const { requestPost } = useRequest();

  const [isLoading, setIsLoading] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (values) => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");

      window.location.reload();
      
      return false;
    }

    try {
      let token = null;

      if (validTokenV3) token = await executeRecaptcha('Login');
      else token = tokenRecaptchaV2;

      logAction('AUTH_LS_Login');

      setIsLoading(true);

      if (!fcmToken) fcmToken = await firebaseMessaging.init(fbApp);

      const params = {
        phoneEmail: `+62${values.phone}`,
        password: EncryptPassword(values.password),
        fcmToken: fcmToken,
        recaptchaToken: token,
        recaptchaVersion: validTokenV3 ? 'v3' : 'v2'
      }

      requestPost(Api.V2.MemberLogin(), params).then(res => {
        dispatch(setAuthData(res?.data));
        onSuccessLogin();

        setTimeout(() => {
          dispatch(setResponseAction({
            message: <>
              <p>Berhasil masuk</p>
            </>,
            status: true,
          }));

          setValidTokenV3(true);

          router?.push(reduxRedirect || redirect || `/user`);
        }, 1000);
      }).catch(err => {
        const errorCode = err?.response?.data?.ErrorCode;
        const errorException = err?.response?.data?.ExceptionType;

        if (errorCode !== '400') {
          if (errorException === 'PhoneNotRegisteredException') {
            dispatch(showError({
              defaultButton: <button type="button" className="font-bold text-secondary-40" onClick={() => {
                dispatch(setNeedSignIn(false));
                dispatch(setNeedSignUp(true));
              }}>Daftar Akun Ideal</button>,
              error: err
            }));
          } else if (errorException === 'CaptchaInvalidException') {
            setValidTokenV3(false);
          } else {
            dispatch(showError({ error: err }));
          }
        } else {
          setLoginError('Kombinasi No. Hp & Password salah');
        }

        setIsLoading(false);
        setTokenRecaptchaV2(null);
      });
    } catch (error) {
      console.log(error?.message);
    }
  };

  return (
    <div className="relative">
      <div className={`mb-7 gap-4 grid ${showRecaptchaSocial ? 'grid-cols-1' : 'grid-cols-1 sm:grid-cols-2'} items-center`}>
        {showRecaptchaSocial ?
          showRecaptchaSocial?.toLowerCase() === 'google' ?
            <ButtonGoogle
              onSuccessLogin={() => {
                dispatch(setNeedSignIn(false));
                onSuccessLogin();
              }}
              onRegistered={() => {
                dispatch(setNeedSignIn(false));
              }}
              setLoading={setLoading}
              setShowRecaptchaSocial={setShowRecaptchaSocial}
              isLoginPage
            />
            :
            <ButtonApple
              onSuccessLogin={() => {
                dispatch(setNeedSignIn(false));
                onSuccessLogin();
              }}
              onRegistered={() => {
                dispatch(setNeedSignIn(false));
              }}
              setLoading={setLoading}
              setShowRecaptchaSocial={setShowRecaptchaSocial}
              isLoginPage
            />
          :
          <Fragment>
            <ButtonGoogle
              onSuccessLogin={() => {
                dispatch(setNeedSignIn(false));
                onSuccessLogin();
              }}
              onRegistered={() => {
                dispatch(setNeedSignIn(false));
              }}
              setLoading={setLoading}
              setShowRecaptchaSocial={setShowRecaptchaSocial}
              isLoginPage
            />
            <ButtonApple
              onSuccessLogin={() => {
                dispatch(setNeedSignIn(false));
                onSuccessLogin();
              }}
              onRegistered={() => {
                dispatch(setNeedSignIn(false));
              }}
              setLoading={setLoading}
              setShowRecaptchaSocial={setShowRecaptchaSocial}
              isLoginPage
            />
          </Fragment>
        }
      </div>

      <div className="w-[310px] mb-6 mx-auto border-b border-neutral-30 relative">
        <span className="py-2 px-4 bg-white text-[10px] leading-[16px] text-neutral-60 absolute -top-[16px] left-[50%] -translate-x-[50%]">
          Atau, masuk dengan
        </span>
      </div>
      <form noValidate autoComplete='off' onSubmit={e => {
        e?.preventDefault();

        onSubmit({
          password: getValuePassword,
          phone: getValuePhone,
        })
      }}>
        <div className='flex flex-col gap-4 relative'>
          <InputText
            allowNegative={false}
            errorMessage={getErrorPhone || loginError}
            leftSpan={'+62'}
            name="phone"
            onBlur={e => {
              const value = e?.target?.value;
              let errorMessage = '';

              if (!value) errorMessage = 'No. HP harus diisi.';
              else if (value?.length < 7) errorMessage = 'No. HP minimal 8 digit.';

              setErrorPhone(errorMessage);
            }}
            onValueChange={values => {
              if (+values?.value === 0 || +values?.value === 62) setValuePhone('');
              else setValuePhone(values?.value);
            }}
            placeholder="Masukkan Nomor Handphone"
            required={true}
            type='phone'
            value={getValuePhone}
            variant='bordered'
          />
          <InputText
            errorMessage={getErrorPassword}
            name="password"
            onBlur={e => {
              const value = e?.target?.value;
              let errorMessage = '';

              if (!value) errorMessage = 'Password harus diisi.';

              setErrorPassword(errorMessage);
            }}
            onChange={e => setValuePassword(e?.target?.value)}
            placeholder="Buat Password"
            required={true}
            type='password'
            value={getValuePassword}
            variant='bordered'
          />
        </div>

        {!validTokenV3 ?
          <Recaptcha token={tokenRecaptchaV2} onChange={token => setTokenRecaptchaV2(token)} />
          : null
        }

        <div className="mt-12">
          <Button
            disabled={(!validTokenV3 && !tokenRecaptchaV2) || !getValuePhone || getErrorPhone || !getValuePassword || getErrorPassword}
            label="Masuk"
            buttonProps={{ type: 'submit' }}
            handler={() => { }}
            full
            loading={isLoading}
          />
        </div>
      </form>
    </div>
  );
};

LoginForm.propTypes = {
  onSuccessLogin: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default LoginForm;
